export const staffData = [
  {
    office_name: "Leadership Teams",
    id: 0,
    office_type: "header",
  },
  {
    office_name: "Executive Team",
    office_initials: "",
    office_address_street: "31 N. Second Street, Suite 300",
    office_address_city: "San Jose, CA 95113",
    office_phone: "Main: 408-995-0714",
    office_fax: "Fax: 408-516-3812",
    id: 0,
    showOffice: false,
    submenu: [
      {
        staff_office: "Executive Team",
        staff_id: 34,
        first_name: "Katie",
        last_name: "Joh",
        suffix_name: "J.D.",
        title: "Chief Executive Officer",
        email: "kjoh@sccdac.org",
        img: "./images/staff_images/katie.jpg",
        bio: "Prior to becoming CEO in January of 2023, Katie was the Managing Attorney for First Call for Families, DAC’s interdisciplinary prevention team. She received her B.A. from Wellesley College, and her J.D. from the University of Michigan Law School. Although she has been passionate about family defense since law school, she began her career as a legal aid attorney learning to address systemic barriers faced by people living in poverty. In 2019 she returned to her original calling and joined DAC to represent parents in dependency court. She then transitioned into  First Call for Families, where she worked to keep families together, and out of the formal child welfare system. Katie draws her motivation for family preservation work from the extraordinary power, resilience, and radical love demonstrated by the families DAC is privileged to serve.",
        showBio: false,
      },
      {
        staff_office: "Executive Team",
        staff_id: 1,
        first_name: "Preeti",
        last_name: "Mishra",
        suffix_name: "J.D., C.W.L.S.",
        title: "Chief Operating Officer",
        email: "pmishra@sccdac.org",
        img: "./images/staff_images/preeti.jpg",
        bio: "Preeti had the privilege to be a co-founder of DAC in 2008 and has been dedicated to the juvenile dependency field for over two decades. She has devoted her career to serving families impacted by the child welfare system and is a certified Child Welfare Law Specialist. DAC’s values of treating clients with respect and compassion as well as creating a nurturing work environment for staff deeply resonate for her. Preeti is particularly passionate about creating practical solutions and processes for DAC's programmatic, funding, and staff talent needs. Apart from work, Preeti enjoys reading, loves spending time with her nephew, and is delighted by elephants.",
        showBio: false,
      },
      {
        staff_office: "Executive Team",
        staff_id: 2,
        first_name: "Hilary",
        last_name: "Kushins",
        suffix_name: "J.D., M.S.W, C.W.L.S.",
        title: "Chief Program Officer",
        email: "hkushins@sccdac.org",
        img: "./images/staff_images/hilary.jpg",
        bio: "Hilary brings over 23 years of experience in the child welfare field, representing parents and children in Santa Clara County's dependency system as a practicing attorney for 20 years and then as Chief Program Officer. She values the experience of having directly represented families in juvenile court and applying lessons learned to advocate for broader systems change. Hilary's passion for social justice and belief that families are the experts in their own lives inspire her to strive toward creating a more just and equitable system. Outside of work, Hilary’s happy place is being curled up with a good book and a strong cup of coffee.",
        showBio: false,
      },
      {
        staff_office: "Executive Team",
        staff_id: 3,
        first_name: "AnnaLisa",
        last_name: "Chung",
        suffix_name: "J.D.",
        title: "Founder in Residence",
        email: "achung@sccdac.org",
        img: "./images/staff_images/amc.jpg",
        bio: "AnnaLisa began her career as a family defense attorney in 2001, prior to becoming a co-founder of DAC and its first CEO. For over two decades, she has served as an advocate for systems change to improve outcomes for families impacted by the child welfare system. AnnaLisa formed critical partnerships to share DAC's approach to holistic representation and ensure the development of best practices throughout the country. She spearheaded Santa Clara County's first Family Reunification Day and paved the way for the creation of DAC's innovative prevention programs. AnnaLisa stepped down from her role as CEO in 2022, and is honored to continue supporting the DAC family and mission.",
        showBio: false,
      },
    ],
  },
  {
    office_name: "Management Team",
    office_initials: "",
    office_address_street: "31 N. Second Street, Suite 300",
    office_address_city: "San Jose, CA 95113",
    office_phone: "Main: 408-995-0714",
    office_fax: "Fax: 408-516-3812",
    id: 1,
    showOffice: false,
    submenu: [
      // {
      //   staff_office: "Office Of Dependency Counsel",
      //   staff_id: 7,
      //   first_name: "Cynthia",
      //   last_name: "Chen",
      //   suffix_name: "J.D., C.W.L.S.",
      //   title: "Supervising Attorney",
      //   email: "cchen@sccdac.org",
      //   img: "./images/staff_images/cynthia.jpg",
      //   bio: "Cynthia has been an attorney for over 17 years and has worked at DAC since early 2018 advocating, advising, and litigating on behalf of our clients. Prior to her juvenile dependency work at DAC, she did dependency-related work and service as a GAL, a board member for First Place for Youth, a juvenile mediator, and as a mentor with youth in foster care. She has always had a passion for strengthening families, advocating on behalf of others, and serving her community. In her current role, she has continued to represent clients, but she is also supervising other attorneys and working to develop, expand, and advocate for a network of resources in the community to better serve all of DAC's clients. ",
      //   showBio: false,
      // }, 
      {
        staff_office: "Corridor Program",
        staff_id: 27,
        first_name: "Sarah",
        last_name: "Cook",
        suffix_name: "J.D., C.W.L.S.",
        title: "Director of Policy and Legal Services",
        email: "scook@sccdac.org",
        img: "./images/staff_images/cook.jpg",
        bio: "Sarah Cook brings over 15 years of experience in family defense, criminal defense, and interdisciplinary representation to her role. She earned her undergraduate degree from Duke University and holds dual degrees in law and social work from Washington University in St. Louis. Sarah is also a certified Child Welfare Law Specialist. She began her career at DAC as a staff attorney with Family Legal Advocates, specializing in the representation of parents and minors in juvenile dependency cases. Sarah has been integral in shaping DAC’s innovative pre-petition/prevention legal services programs. Sarah is also actively involved in all of DAC’s policy and advocacy efforts on behalf of families impacted by the child welfare system and was recently appointed to serve on the Child Welfare Council of California’s statewide Mandated Reporter to Community Supporting Task Force.",
        showBio: false,
      },
      {
        staff_office: "First Call For Families",
        staff_id: 62,
        first_name: "Brian",
        last_name: "Jones",
        suffix_name: "J.D.",
        title: "First Call for Families Managing Attorney",
        email: "bjones@sccdac.org",
        img: "./images/staff_images/brian.jpg",
        bio: "Brian E. Jones brings his expertise from working with the Family Defense Practice at Brooklyn Defender Services from 2018 to 2021. Currently at DAC, Brian advises parents during DFCS investigations and voluntary cases while providing essential training to community members and service providers. He values DAC's holistic, interdisciplinary approach to client representation, making it a unique and fulfilling place to work.",
        showBio: false,
      },
      // {
      //   staff_office: "Corridor Program",
      //   staff_id: 50,
      //   first_name: "Crystal",
      //   last_name: "Mendoza-Velarde",
      //   suffix_name: "J.D.",
      //   title: "Corridor Managing Attorney",
      //   email: "cmendozavelarde@sccdac.org",
      //   img: "./images/staff_images/crystal.jpg",
      //   bio: "As a proud daughter of Mexican immigrants, Crystal Mendoza-Velarde’s fuel to advocate for her clients comes from growing up and witnessing her family and community’s struggles in navigating various legal systems. Throughout her journey as a first-generation college and law school graduate, her commitment has been to serve communities like the ones she grew up in: underrepresented, low-income, and people of color. Crystal earned her J.D. from Golden Gate University, School of Law and received her B.A. in English Literature from San Jose State University.  Since 2020, she has been able to proudly serve her community through her role as a Staff Attorney with DAC. Outside of the courtroom, she enjoys spending time with her family, reading books, and researching the best shades of lipstick available.",
      //   showBio: false,
      // },
      {
        staff_office: "Conflicts Administrative Staff",
        staff_id: 4,
        first_name: "Nicole",
        last_name: "Radden",
        suffix_name: "",
        title: "Director of Operations",
        email: "nradden@sccdac.org",
        img: "./images/staff_images/nicole.jpg",
        bio: "Nicole joined DAC in 2022. As the Director of Operations for DAC, Nicole brings a strong academic foundation in Business Administration along with a degree in Public Policy from UC Riverside. With extensive management experience spanning the HR, Education, and IT fields, Nicole plays a multifaceted role that is crucial to DAC’s success. Nicole resonates with DAC’s commitment to community and passionately supports DAC’s mission to disrupt the cycle of systemic involvement. She enjoys being involved in church, crafting, graphic design, and is passionate about sports, especially Softball and USC Trojan Football.",
        showBio: false,
      },
      {
        staff_office: "Social Worker Program",
        staff_id: 23,
        first_name: "Maggie",
        last_name: "Ross",
        suffix_name: "L.C.S.W.",
        title: "Clinical Director",
        email: "mross@sccdac.org",
        img: "./images/staff_images/margaret.jpg",
        bio: "Maggie Ross brings over a decade of experience working in the intersections of the mental health and criminal justice systems to her roles at DAC, including crisis response, gang intervention, re-entry stabilization, addiction support, clinical research, trauma focused therapy and case management. Maggie is passionate about ensuring families have access not only to resources and the advocacy, but also to the time, community and therapeutic tools needed to heal from the immense multigenerational pain often caused by the Child Welfare System. She is driven to continue developing DAC’s clinical support for every family with whom DAC works. In her free time, Maggie enjoys painting and exploring the mountains and beaches with her family and two energetic dogs.",
        showBio: false,
      },
      {
        staff_office: "Mentor Parent Program",
        staff_id: 15,
        first_name: "Dave",
        last_name: "Shuster",
        suffix_name: "",
        title: "Mentor Parent Program Manager",
        email: "dshuster@sccdac.org",
        phone: "",
        img: "./images/staff_images/dave.jpg",
        bio: "As the Mentor Parent Program Manager, Dave supervises seven Mentor Parents dedicated to assisting child welfare-involved parents facing various challenges. He manages case assignments, data collection, professional development, HR matters, training, contract management, and provides support during difficult cases. Dave's passion lies in helping families regain their dignity, reintegrate into society, and become positive role models for their children. He's a proud father of 4 children and 2 grandchildren, celebrating well over a decade and counting of sobriety.",
        showBio: false,
      }, 
    ]
  },
  {
    office_name: "Administrative Assistants",
    id: 2,
    office_type: "header",
  },
  {
    office_name: "Administrative Assistants",
    office_initials: "",
    office_address_street: "31 N. Second Street, Suite 300",
    office_address_city: "San Jose, CA 95113",
    office_phone: "Main: 408-995-0714",
    office_fax: "Fax: 408-516-3812",
    id: 2,
    showOffice: false,
    submenu: [
      {
        staff_office: "Administrative Assistants",
        staff_id: 6,
        first_name: "Karmen",
        last_name: "Perales",
        suffix_name: "",
        title: "Administrative Assistant & Prevention Intake Specialist",
        email: "kperales@sccdac.org",
        img: "./images/staff_images/karmen.jpg",
        bio: "Karmen, an Administrative Assistant at DAC, Has been with the organization since 2008. Her journey with DAC began as a former client, progressing from a Mentor Parent to her current role. Karmen deeply values her continued involvement in DAC's impactful work for the families they serve. Her story reflects the transformative power of DAC's programs, as she transitioned from receiving support to giving it. Working for DAC has instilled in her a profound sense of purpose, allowing her to give back the support she once received. Beyond her work, Karmen has a passion for cooking and baking.",
        showBio: false,
      },
      {
        staff_office: "Conflicts Administrative Staff",
        staff_id: 5,
        first_name: "Franchesca",
        last_name: "Tran",
        suffix_name: "",
        title: "Administrative Assistant & Court Intake Specialist / DAC Notary",
        email: "ftran@sccdac.org",
        img: "./images/staff_images/fran.jpg",
        bio: "Franchesca holds a Bachelor of Arts degree in Liberal Arts in Education and serves as the first face contact person for DAC as an administrative assistant. Franchesca passionately believes in DAC's mission of reuniting families. Outside of her professional life, she dedicates her time to her family. She enjoys nature, appreciating sunsets, and listening to her favorite podcasts.",
        showBio: false,
      },
    ],
  },
  {
    office_name: "Court Appointed Family Defense Staff",
    id: 3,
    office_type: "header",
  },
  {
    office_name: "Office of Dependency Counsel",
    office_initials: "",
    office_address_street: "31 N. Second Street, Suite 300B",
    office_address_city: "San Jose, CA 95113",
    office_phone: "",
    office_fax: "",
    id: 3,
    showOffice: false,
    submenu: [
      // {
      //   staff_office: "Office Of Dependency Counsel",
      //   staff_id: 7,
      //   first_name: "Cynthia",
      //   last_name: "Chen",
      //   suffix_name: "J.D., C.W.L.S.",
      //   title: "Supervising Attorney",
      //   email: "cchen@sccdac.org",
      //   img: "./images/staff_images/cynthia.jpg",
      //   bio: "Cynthia has been an attorney for over 17 years and has worked at DAC since early 2018 advocating, advising, and litigating on behalf of our clients. Prior to her juvenile dependency work at DAC, she did dependency-related work and service as a GAL, a board member for First Place for Youth, a juvenile mediator, and as a mentor with youth in foster care. She has always had a passion for strengthening families, advocating on behalf of others, and serving her community. In her current role, she has continued to represent clients, but she is also supervising other attorneys and working to develop, expand, and advocate for a network of resources in the community to better serve all of DAC's clients. ",
      //   showBio: false,
      // },
      {
        staff_office: "Office Of Depedency Counsel",
        staff_id: 60,
        first_name: "Bhavika",
        last_name: "Anandpura",
        suffix_name: "J.D.",
        title: "Staff Attorney",
        email: "banandpura@sccdac.org",
        img: "./images/staff_images/bhavika.jpg",
        bio: "Bhavika is an attorney with Office Of Depedency Counsel. She is dedicated to empowering families to thrive without CPS involvement. When she was attending UC Berkeley Law School, Bhavika learned how to advocate for parents in dependency court. Now, working with the FCF team alongside mentor parents and social workers is very meaningful for Bhavika and motivates her to fight for her clients.",
        showBio: false,
      },
      {
        staff_office: "Office Of Depedency Counsel",
        staff_id: 8,
        first_name: "Jasmine",
        last_name: "Wang",
        suffix_name: "J.D.",
        title: "Staff Attorney",
        email: "jwang@sccdac.org",
        img: "./images/staff_images/jasmine.jpg",
        bio: "Jasmine is a Staff Attorney with the Office of Dependency Counsel. She is dedicated to providing compassionate, holistic, and high-quality legal representation for parents and children. She believes in the importance of family reunification and in supporting the dignity of her clients. Jasmine received her JD in 2020 from the University of Michigan where she was also a student-attorney in the Child Advocacy Law Clinic. Prior to DAC, Jasmine worked as a litigation associate at the law firm Covington and Burling. Jasmine has been practicing at DAC since March 2022.",
        showBio: false,
      },
    ],
  },
  {
    office_name: "Family Legal Advocates",
    office_initials: "",
    office_address_street: "31 N. Second Street, Suite 300A",
    office_address_city: "San Jose, CA 95113",
    office_phone: "",
    office_fax: "",
    id: 4,
    showOffice: false,
    submenu: [
      {
        staff_office: "Family Legal Advocates",
        staff_id: 10,
        first_name: "Margaret",
        last_name: "Burks",
        suffix_name: "J.D.",
        title: "Senior Staff Attorney",
        email: "mburks@sccdac.org",
        img: "./images/staff_images/burks.jpg",
        bio: "Margaret, a seasoned Family Legal Advocates Staff Attorney, has been an integral part of DAC since August 2015. With a Juris Doctorate earned in May 1995 she has navigated California Dependency courts in five counties. Margaret excels in client interactions and trial skills, finding solace in photography and whale watching as a creative escape from the demands of her challenging profession.",
        showBio: false,
      },
    ],
  },
  {
    office_name: "Dependency Legal Associates",
    office_initials: "",
    office_address_street: "31 N. Second Street, Suite 300C",
    office_address_city: "San Jose, CA 95113",
    office_phone: "",
    office_fax: "",
    id: 5,
    showOffice: false,
    submenu: [
      {
        staff_office: "Dependency Legal Associates",
        staff_id: 11,
        first_name: "Maggie",
        last_name: "Law",
        suffix_name: "J.D.",
        title: "Staff Attorney",
        email: "mlaw@sccdac.org",
        img: "./images/staff_images/maggie.jpg",
        bio: "As a senior member of the attorney staff, Maggie represents both parents and children with zeal and empathy. Equally at home litigating and mediating for best possible outcomes, Maggie draws upon ten years' experience practicing Dependency law and ten years' experience as a Public Defender. Prior to her law career Maggie worked in the corporate world as a training specialist.",
        showBio: false,
      },
      {
        staff_office: "Dependency Legal Associates",
        staff_id: 12,
        first_name: "Adam",
        last_name: "Rust",
        suffix_name: "J.D.",
        title: "Staff Attorney",
        email: "arust@sccdac.org",
        img: "./images/staff_images/adam.jpg",
        bio: "Adam Rust has worked with DAC since 2020. His prior legal practice includes a variety of legal practice areas include criminal law, family law, immigration law, housing discrimination and employment law. His work at DAC is a continuation of his commitment to people-centered law. In his downtime he watches old movies, reads old books, and spends time with old friends.",
        showBio: false,
      },
    ],
  },
  {
    office_name: "Family Defense Counsel",
    office_initials: "",
    office_address_street: "31 N. Second Street, Suite 300F",
    office_address_city: "San Jose, CA 95113",
    office_phone: "",
    office_fax: "",
    id: 6,
    showOffice: false,
    submenu: [
      // {
      //   staff_office: "Family Defense Counsel",
      //   staff_id: 13,
      //   first_name: "Sarah",
      //   last_name: "Brauer",
      //   suffix_name: "J.D.",
      //   title: "Staff Attorney",
      //   email: "sbrauer@sccdac.org",
      //   img: "./images/staff_images/brauer.jpg",
      //   bio: "Sarah, a native of San Jose, California, holds a Juris Doctorate and a Social Justice certificate from Santa Clara University School of Law, specializing in Criminal Law. She gained experience in both state and federal courts during her internships at the Santa Clara County and Stanislaus County Public Defender Offices, Federal Public Defender Office in Sacramento, and during her fellowship with the Northern California Innocence Project. Sarah began her career in criminal defense, representing clients in numerous Northern California counties. Since 2021, as a Staff Attorney, she's been dedicated to advocating for her clients and fighting against injustices in family defense. Her strong ties to the local community and diverse immigrant and native heritage inspires her to help her clients navigate the legal system. Outside of work, she enjoys boxing, jiu-jitsu, and lacrosse.",
      //   showBio: false,
      // },
      {
        staff_office: "Family Defense Counsel",
        staff_id: 14,
        first_name: "Arthur",
        last_name: "Tan",
        suffix_name: "J.D.",
        title: "Staff Attorney",
        email: "atan@sccdac.org",
        img: "./images/staff_images/arthur.jpg",
        bio: "Arthur has been a staff attorney at DAC since 2017. From 2014 to 2017, Arthur was a Guardian Ad Litem with the juvenile court. Arthur graduated from the University of Miami School of Law in 2002. He is licensed to practice in Florida, New York, and California. Outside of work, Arthur enjoys traveling.",
        showBio: false,
      },
    ],
  },
  {
    office_name: "Mentor Parent Program (Court Cases)",
    office_initials: "",
    office_address_street: "31 N. Second Street, Suite 300D",
    office_address_city: "San Jose, CA 95113",
    office_phone: "",
    office_fax: "",
    id: 7,
    showOffice: false,
    submenu: [
      {
        staff_office: "Mentor Parent Program",
        staff_id: 15,
        first_name: "Dave",
        last_name: "Shuster",
        suffix_name: "",
        title: "Mentor Parent Program Manager",
        email: "dshuster@sccdac.org",
        phone: "",
        img: "./images/staff_images/dave.jpg",
        bio: "As the Mentor Parent Program Manager, Dave supervises seven Mentor Parents dedicated to assisting child welfare-involved parents facing various challenges. He manages case assignments, data collection, professional development, HR matters, training, contract management, and provides support during difficult cases. Dave's passion lies in helping families regain their dignity, reintegrate into society, and become positive role models for their children. He's a proud father of 4 children and 2 grandchildren, celebrating well over a decade and counting of sobriety.",
        showBio: false,
      },
      {
        staff_office: "Mentor Parent Program",
        staff_id: 18,
        first_name: "Mario",
        last_name: "Arenas",
        suffix_name: "",
        title: "Mentor Parent",
        email: "marenas@sccdac.org",
        phone: "",
        img: "./images/staff_images/mario.jpg",
        bio: "Mario has been an integral part of DAC since 2019. His greatest asset and biggest inspiration is his beautiful son, that he thankfully brought home from foster care a day before his first birthday.  Mario successfully journeyed through the child welfare system as a single father. His faith gave him the motivation and courage to stay the course on his road to recovery. He uses firsthand knowledge to encourage, empower and guide fathers through the system as a DAC Mentor Parent. Mario contributes valuable “real” advice, emotional and empathetic encouragement, and priceless wisdom to those striving to reunify with their children. He loves to witness Santa Clara County families bringing their children home because it’s such a blessing!",
        showBio: false,
      },
      {
        staff_office: "Mentor Parent Program",
        staff_id: 21,
        first_name: "Osie",
        last_name: "Castaneda",
        suffix_name: "",
        title: "Mentor Parent",
        email: "ocastaneda@sccdac.org",
        phone: "",
        img: "./images/staff_images/osie.jpg",
        bio: "Osie, a Mentor Parent at DAC, passionately provides resources, non-judgmental advice, and a foundation of trust to her clients, fostering deep connections recognized by attorneys and judges. Osie's personal journey motivates her to support mothers seeking to reunite with their children, as she lacked this support herself. She values DAC's employee-centric culture and finds inspiration in her supervisor and the DAC family. Osie's life experiences, including overcoming addiction and surviving domestic violence, allow her to empathize and empower others. Outside of work, she enjoys cooking, family time, karaoke, and going to the gym.",
        showBio: false,
      },
      {
        staff_office: "Mentor Parent Program",
        staff_id: 19,
        first_name: "Melissa",
        last_name: "Elias",
        suffix_name: "",
        title: "Mentor Parent",
        email: "melias@sccdac.org",
        phone: "",
        img: "./images/staff_images/melissa.jpg",
        bio: "Melissa, a Mentor Parent, is dedicated to guiding and supporting mothers through the dependency system. She leads by example, offering unconditional love and empowering her clients to become the best versions of themselves. Melissa's personal journey as a former client who lacked support during her own reunification motivates her to provide mothers with the support, she wished she had received.",
        showBio: false,
      },
      {
        staff_office: "Mentor Parent Program",
        staff_id: 16,
        first_name: "Alyssa",
        last_name: "Lopez",
        suffix_name: "",
        title: "Senior Mentor Parent",
        email: "alopez@sccdac.org",
        phone: "",
        img: "./images/staff_images/alyssa.jpg",
        bio: "Alyssa Lopez, an accomplished Mentor Parent at DAC, is a beacon of transformation and hope. She received DAC's 2018 Parent of the Year Award and 2018 Family Reunification Day recognition for successfully reuniting with her children, defying her past as a product of the system. Alyssa, a first-generation high school graduate and close to earning her AA in Business, actively participates as a Systems Change Mentor in committees, bringing her lived experience to shape policies. She guides, empowers and supports mothers through the system while witnessing their genuine transformations. Outside of her dedication to DAC, Alyssa enjoys family time, world travel, Geocaching, sports, games, and adventure.",
        showBio: false,
      },
      {
        staff_office: "Mentor Parent Program",
        staff_id: 17,
        first_name: "Brenda",
        last_name: "Manzo",
        suffix_name: "",
        title: "Mentor Parent",
        email: "bmanzo@sccdac.org",
        phone: "",
        img: "./images/staff_images/brenda.jpg",
        bio: "Brenda Manzo, a Mentor Parent at DAC, started her journey as a client in the Corridor program and holds a Mentor Certification from SJCC. Currently a student at SJCC, she aims to expand her capacity to support DAC clients. In her role, Brenda assists mothers in meeting court-ordered obligations for child reunification, providing unwavering support during this vulnerable time in their lives. She's driven by her dream to prove that reunification is possible. Outside of work she enjoys making cherished family memories, exploring new places, savoring good food, and cheering for the 49ers during football season.",
        showBio: false,
      },
      {
        staff_office: "Mentor Parent Program",
        staff_id: 20,
        first_name: "Rehmi",
        last_name: "Ruiz",
        suffix_name: "",
        title: "Senior Mentor Parent",
        email: "rruiz@sccdac.org",
        phone: "",
        img: "./images/staff_images/rehmi.jpg",
        bio: "Rehmi is a dedicated mentor parent with a passion for helping mothers involved in the dependency system. Rehmi helps mothers with intimate partner violence trauma, substance use disorder trauma and those with criminal justice histories. Rehmi has been with DAC since 2017. In her spare time, she loves being involved with her church, working at her part-time job, and spending time with her son.",
        showBio: false,
      },
      {
        staff_office: "Mentor Parent Program",
        staff_id: 22,
        first_name: "Louis",
        last_name: "Vidal",
        suffix_name: "",
        title: "Mentor Parent",
        email: "lvidal@sccdac.org",
        phone: "",
        img: "./images/staff_images/louis.jpg",
        bio: "Louie Vidal, a Mentor Parent at DAC, has triumphed over addiction and a history of incarceration. Drawing from his personal experience with addiction, Louie empathizes with clients currently facing similar struggles. He actively supports their journey toward family reunification. Outside of his role at DAC, Louie attends church, Alcoholics Anonymous (A/A), and Narcotics Anonymous (N/A) meetings, demonstrating his commitment to his own recovery and his dedication to helping others overcome addiction and rebuild their lives with their families.",
        showBio: false,
      },
    ],
  },
  {
    office_name: "Social Workers Program (Court Cases)",
    office_initials: "",
    office_address_street: "31 N. Second Street, Suite 300",
    office_address_city: "San Jose, CA 95113",
    office_phone: "",
    office_fax: "",
    id: 8,
    showOffice: false,
    submenu: [
      {
        staff_office: "Social Worker Program",
        staff_id: 23,
        first_name: "Maggie",
        last_name: "Ross",
        suffix_name: "L.C.S.W.",
        title: "Clinical Director",
        email: "mross@sccdac.org",
        img: "./images/staff_images/margaret.jpg",
        bio: "Maggie Ross brings over a decade of experience working in the intersections of the mental health and criminal justice systems to her roles at DAC, including crisis response, gang intervention, re-entry stabilization, addiction support, clinical research, trauma focused therapy and case management. Maggie is passionate about ensuring families have access not only to resources and the advocacy, but also to the time, community and therapeutic tools needed to heal from the immense multigenerational pain often caused by the Child Welfare System. She is driven to continue developing DAC’s clinical support for every family with whom DAC works. In her free time, Maggie enjoys painting and exploring the mountains and beaches with her family and two energetic dogs.",
        showBio: false,
      },
      {
        staff_office: "Social Worker Program",
        staff_id: 25,
        first_name: "Carolyn",
        last_name: "El-Shammaa",
        suffix_name: "M.S.W.",
        title: "Social Worker",
        email: "celshammaa@sccdac.org",
        img: "./images/staff_images/carolyn.jpg",
        bio: "Carolyn El-Shammaa, a bilingual Social Worker, has been an integral part of DAC since November 2021. She holds a BA from UW-Madison and an MSW from UC-Berkeley. Carolyn has over ten years working with various Family and Children's Services agencies.   However, she was drawn to DAC because of their mission as well as the interdisciplinary approach DAC takes.  Carolyn enjoys spending her spare time with her 4 children and a variety of pets.",
        showBio: false,
      },
      {
        staff_office: "Social Worker Program",
        staff_id: 24,
        first_name: "Kira",
        last_name: "Johnson",
        suffix_name: "M.S.W",
        title: "Senior Social Worker",
        email: "kjohnson@sccdac.org",
        img: "./images/staff_images/kira.jpg",
        bio: "Kira Johnson is a highly experienced social worker with over twenty years of dedicated service in the field. As a trauma-informed practitioner, Kira firmly believes in employing a strength-based approach to empower families and promote their overall wellness. Kira's commitment to cultural and emotional competence is evident in her work. As a co-chair on the Diversity, Equity, and Inclusion committee at DAC, she plays a vital role in promoting inclusivity and equality within the organization.",
        showBio: false,
      },
    ],
  },
  {
    office_name: "Prevention Services Staff",
    id: 9,
    office_type: "header",
  },
  {
    office_name: "Corridor Program",
    office_initials: "",
    office_address_street: "31 N. Second Street, Suite 300E",
    office_address_city: "San Jose, CA 95113",
    office_phone: "Main: 408-564-5142",
    office_fax: "Fax: 408-512-1697",
    id: 10,
    showOffice: false,
    submenu: [
      // {
      //   staff_office: "Corridor Program",
      //   staff_id: 28,
      //   first_name: "Crystal",
      //   last_name: "Mendoza-Velarde",
      //   suffix_name: "J.D.",
      //   title: "Managing Attorney",
      //   email: "cmendozavelarde@sccdac.org",
      //   img: "./images/staff_images/crystal.jpg",
      //   bio: "As a proud daughter of Mexican immigrants, Crystal Mendoza-Velarde’s fuel to advocate for her clients comes from growing up and witnessing her family and community’s struggles in navigating various legal systems. Throughout her journey as a first-generation college and law school graduate, her commitment has been to serve communities like the ones she grew up in: underrepresented, low-income, and people of color. Crystal earned her J.D. from Golden Gate University, School of Law and received her B.A. in English Literature from San Jose State University.  Since 2020, she has been able to proudly serve her community through her role as a Staff Attorney with DAC. Outside of the courtroom, she enjoys spending time with her family, reading books, and researching the best shades of lipstick available.",
      //   showBio: false,
      // },
      {
        staff_office: "Corridor Program",
        staff_id: 52,
        first_name: "Abby",
        last_name: "Lederman",
        suffix_name: "J.D.",
        title: "Staff Attorney",
        email: "alederman@sccdac.org",
        img: "./images/staff_images/abby.jpg",
        bio: "As part of DAC’s Corridor program, Abigail collaborates with social workers and mentor parents to provide wraparound support to parents at the intersection of the criminal and family policing systems. Before coming to DAC, she developed a family law project within an Oakland-based reentry organization, providing legal advice and direct representation to clients impacted by the criminal legal system. Earlier, Abigail’s first experience working with families navigating the risk or reality of foster care led her to pursue a law degree at NYU, where she participated in the Family Defense Clinic. She is thrilled to join a team that so expertly fights for families to be trusted and empowered to remain intact, to heal, and to thrive.",
        showBio: false,
      },
      {
        staff_office: "Corridor Program",
        staff_id: 30,
        first_name: "Alondra",
        last_name: "DeSantigo",
        suffix_name: "M.S.W., A.C.S.W.",
        title: "Social Worker",
        email: "aalcaraz@sccdac.org",
        img: "./images/staff_images/alondra.jpg",
        bio: "As a Corridor Social Worker at DAC, Alondra brings her expertise to support families navigating complex systems, such as the Criminal Justice and Dependency systems. With a Bachelor's in Social Work from San Jose State University (2017) and a Master's in Social Work (2021), she's dedicated to helping families access community resources and develop self-sufficiency skills during challenging times. Alondra's commitment lies in providing unwavering support during their most vulnerable moments, embodying hope and a fresh start that DAC stands for. Outside of her work, she enjoys quality family time, exploring National Parks, and savoring new culinary experiences.",
        showBio: false,
      },
      {
        staff_office: "Corridor Program",
        staff_id: 31,
        first_name: "Kimberley",
        last_name: "Mejia",
        suffix_name: "M.S.W., A.S.W.",
        title: "Social Worker",
        email: "kmejia@sccdac.org",
        img: "./images/staff_images/default.png",
        bio: "Kimberley joined DAC as a part of the Corridor team because she is passionate about supporting folks navigate intricate and difficult systems. She holds a bachelor's and master's degree in social work, both from San Jose State and has years of experience providing intensive case management, crisis intervention, mental health services, and harm reduction support. Kimberley is passionate about advocacy, abolition, decolonization, and empowering people to set and achieve their goals. Outside of her professional life, Kimberley enjoys film photography, collecting vinyl records, reading, and traveling.",
        showBio: false,
      },
      {
        staff_office: "Corridor Program",
        staff_id: 29,
        first_name: "Kimberly",
        last_name: "Valencia",
        suffix_name: "",
        title: "Paralegal",
        email: "kvalencia@sccdac.org",
        img: "./images/staff_images/kimberly.jpg",
        bio: "Kimberly Valencia Morales, a paralegal since August 2022, earned her Bachelor of Arts in Criminal Justice Studies from SFSU in 2016. She later pursued paralegal certification at Evergreen Valley College. Currently, Kimberly serves as a paralegal for Corridor and First Call for Families, where she manages various responsibilities, but what truly inspires her is connecting with clients and witnessing their transformations. She's drawn to the nonprofit space for its meaningful impact on the community. Beyond work, Kimberly enjoys nature, spending time with family, her cat Bixby, and engaging in creative DIY projects.",
        showBio: false,
      },
      {
        staff_office: "Corridor Program",
        staff_id: 32,
        first_name: "Parish",
        last_name: "Green",
        suffix_name: "",
        title: "Senior Mentor Parent",
        email: "pgreen@sccdac.org",
        phone: "",
        img: "./images/staff_images/parish.jpg",
        bio: "Parish Green, a Corridor Mentor Parent at DAC, recently transitioned into the Systems Change role. He brings real-world experience, having attended high school and junior college before exploring other opportunities, including MDI for mentoring and leadership training. Parish is a natural leader, connecting with men during their most vulnerable moments and inspiring positive change by sharing his own journey and supporting them through 12-step fellowships. He has guided over 20 men through the 12 steps, fueled by his passion for transforming lives and reuniting families. Outside of work, Parish indulges his love for music, is a vocalist, and enjoys outdoor activities like fishing.",
        showBio: false,
      },
      {
        staff_office: "Corridor Program",
        staff_id: 35,
        first_name: "Jose",
        last_name: "Fontanillo",
        suffix_name: "",
        title: "Mentor Parent",
        email: "jfontanillo@sccdac.org",
        phone: "",
        img: "./images/staff_images/jose.jpg",
        bio: "Jose  has overcome significant challenges, including addiction and homelessness. Inspired by his son, he now helps others on their recovery journey. Jose finds fulfillment in offering hope and support, both at DAC and through community involvement in programs like Echo Compassion, AA, and NA.",
        showBio: false,
      },
      {
        staff_office: "Corridor Program",
        staff_id: 33,
        first_name: "Victoria",
        last_name: "Ruiz",
        suffix_name: "",
        title: "Senior Mentor Parent",
        email: "vruiz@sccdac.org",
        phone: "",
        img: "./images/staff_images/victoria.jpg",
        bio: "DAC represented Victoria in both 2004 and 2008 through her own dependency case and she was moved by the support, encouragement and mentorship that she received from DAC during that time. Victoria was hired as a Mentor Parent in 2012. Working for DAC seemed like a dream, being able to work closely with other parents who have an opportunity for change and to be freed from the bondage of addiction to substances. She believes it is a gift to be able to support and believe in someone who does not believe in themselves and watch them grow.",
        showBio: false,
      },
    ],
  },
  {
    office_name: "First Call For Families",
    office_initials: "",
    office_address_street: "31 N. Second Street, Suite 300G",
    office_address_city: "San Jose, CA 95113",
    office_phone: "Main: 408-856-2992",
    office_fax: "Fax: 408-856-2996",
    id: 11,
    showOffice: false,
    submenu: [
      // {
      //   staff_office: "Corridor Program",
      //   staff_id: 27,
      //   first_name: "Sarah",
      //   last_name: "Cook",
      //   suffix_name: "J.D., C.W.L.S.",
      //   title: "Interim Managing Attorney",
      //   email: "scook@sccdac.org",
      //   img: "./images/staff_images/cook.jpg",
      //   bio: "Sarah Cook brings over 15 years of experience in family defense, criminal defense, and interdisciplinary representation to her role. She earned her undergraduate degree from Duke University and holds dual degrees in law and social work from Washington University in St. Louis. Sarah is also a certified Child Welfare Law Specialist. She began her career at DAC as a staff attorney with Family Legal Advocates, specializing in the representation of parents and minors in juvenile dependency cases. Sarah has been integral in shaping DAC’s innovative pre-petition/prevention legal services programs. Sarah is also actively involved in all of DAC’s policy and advocacy efforts on behalf of families impacted by the child welfare system and was recently appointed to serve on the Child Welfare Council of California’s statewide Mandated Reporter to Community Supporting Task Force.",
      //   showBio: false,
      // },
      {
        staff_office: "First Call For Families",
        staff_id: 37,
        first_name: "Brian",
        last_name: "Jones",
        suffix_name: "J.D.",
        title: "Managing Attorney",
        email: "bjones@sccdac.org",
        img: "./images/staff_images/brian.jpg",
        bio: "Brian E. Jones brings his expertise from working with the Family Defense Practice at Brooklyn Defender Services from 2018 to 2021. Currently at DAC, Brian advises parents during DFCS investigations and voluntary cases while providing essential training to community members and service providers. He values DAC's holistic, interdisciplinary approach to client representation, making it a unique and fulfilling place to work.",
        showBio: false,
      },
      {
        staff_office: "First Call For Families",
        staff_id: 36,
        first_name: "Bhavika",
        last_name: "Anandpura",
        suffix_name: "J.D.",
        title: "Staff Attorney",
        email: "banandpura@sccdac.org",
        img: "./images/staff_images/bhavika.jpg",
        bio: "Bhavika is an attorney with First Call for Families. She is dedicated to empowering families to thrive without CPS involvement. When she was attending UC Berkeley Law School, Bhavika learned how to advocate for parents in dependency court. Now, working with the FCF team alongside mentor parents and social workers is very meaningful for Bhavika and motivates her to fight for her clients.",
        showBio: false,
      },
      {
        staff_office: "First Call For Families",
        staff_id: 61,
        first_name: "Jasmine",
        last_name: "Wang",
        suffix_name: "J.D.",
        title: "Staff Attorney",
        email: "jwang@sccdac.org",
        img: "./images/staff_images/jasmine.jpg",
        bio: "Jasmine is a Staff Attorney with First Call For Families. She is dedicated to providing compassionate, holistic, and high-quality legal representation for parents and children. She believes in the importance of family reunification and in supporting the dignity of her clients. Jasmine received her JD in 2020 from the University of Michigan where she was also a student-attorney in the Child Advocacy Law Clinic. Prior to DAC, Jasmine worked as a litigation associate at the law firm Covington and Burling. Jasmine has been practicing at DAC since March 2022.",
        showBio: false,
      },
      {
        staff_office: "First Call For Families",
        staff_id: 39,
        first_name: "Cecilia",
        last_name: "Galvan",
        suffix_name: "M.S.W., A.S.W.",
        title: "Social Worker",
        email: "cgalvan@sccdac.org",
        img: "./images/staff_images/cecilia.jpg",
        bio: "Cecilia Galvan holds a Master of Social Work from the University of Michigan and is licensed in Pennsylvania and California. Cecilia has been a Social Worker at First Call for Families for one year, her role at DAC includes safety planning, client advocacy, and community outreach. Cecilia values the personal connections with clients and the positive impact her team has on families and the community. Outside of work, she enjoys outdoor activities, sports, photography, community involvement, arts and crafts, and traveling.",
        showBio: false,
      },
      {
        staff_office: "Social Worker Program",
        staff_id: 40,
        first_name: "Claudia",
        last_name: "Marquez",
        suffix_name: "A.C.S.W.",
        title: "Social Worker",
        email: "cmarquez@sccdac.org",
        img: "./images/staff_images/claudia.jpg",
        bio: "Claudia is a dedicated social worker for DAC. She obtained her MSW from San José State University in May 2021 and is currently working towards licensure. Claudia serves as a prevention specialist at First Call for Families, advocating for parents and connecting them with community resources. Raised in San José, she is passionate about supporting her community and enjoys her role at DAC, where she feels like part of a family. Claudia excels at building connections with parents, values family time, and loves exploring the diverse culinary scene of the Bay Area.",
        showBio: false,
      },
      {
        staff_office: "First Call For Families",
        staff_id: 38,
        first_name: "Kimberly",
        last_name: "Valencia",
        suffix_name: "",
        title: "Paralegal",
        email: "kvalencia@sccdac.org",
        img: "./images/staff_images/kimberly.jpg",
        bio: "Kimberly Valencia Morales, a paralegal since August 2022, earned her Bachelor of Arts in Criminal Justice Studies from SFSU in 2016. She later pursued paralegal certification at Evergreen Valley College. Currently, Kimberly serves as a paralegal for Corridor and First Call for Families, where she manages various responsibilities, but what truly inspires her is connecting with clients and witnessing their transformations. She's drawn to the nonprofit space for its meaningful impact on the community. Beyond work, Kimberly enjoys nature, spending time with family, her cat Bixby, and engaging in creative DIY projects.",
        showBio: false,
      },
      {
        staff_office: "First Call For Families",
        staff_id: 43,
        first_name: "Gildardo",
        last_name: "Amador",
        suffix_name: "",
        title: "Mentor Parent",
        email: "gamador@sccdac.org",
        img: "./images/staff_images/gilardo.jpg",
        bio: "As a parent that has successfully navigated the child welfare system, Gildardo utilizes his first hand knowledge and lived experience to advocate, support, and empower parents within the system as a DAC mentor parent. Gildardo provides invaluable insight, supportive advice, and emotional encouragement to those striving to reunify and assists in developing the tools to help parents and prevent system involvement. Gildardo is very passionate of the work that he does in supporting and assisting the families of Santa Clara county.",
        showBio: false,
      },
      {
        staff_office: "First Call For Families",
        staff_id: 42,
        first_name: "Kristi",
        last_name: "Coronado",
        suffix_name: "",
        title: "Mentor Parent",
        email: "kcoronado@sccdac.org",
        img: "./images/staff_images/kristi.jpg",
        bio: "Kristi is a FCF Mentor Parent at DAC filled with excitement and dedication to the support of her clients. She is very serious about her recovery as well as prevention. As a former recipient of the reunification award where she was honored for successfully reunifying against all odds, she knows what it takes to fight. She desires to see families intact and healthy. She is committed to the difficult yet rewarding path that faces those affected by addiction. She is a mother of 6 beautiful children and loves to spend time and cook for them every free moment she has. Aside from her role at DAC, she finds her role as a single parent to be the most rewarding job she has ever had. She looks forward to a bright future at DAC helping as many moms as possible!",
        showBio: false,
      },
      {
        staff_office: "First Call For Families",
        staff_id: 41,
        first_name: "Ana",
        last_name: "Navarez",
        suffix_name: "",
        title: "Mentor Parent",
        email: "anevarez@sccdac.org",
        img: "./images/staff_images/ana.jpg",
        bio: "As a dedicated Mentor Parent at DAC , Ana brings a wealth of personal and educational experience. Ana holds a Peer Mentor Certification from San Jose City College and is pursuing Drug and Alcohol Counselor certification. She's dedicated to empowering clients, connecting them with crucial support, and teaching self-advocacy. Ana's personal journey led her to DAC, where she passionately supports clients and finds joy in their successes. Outside work, she cherishes family time, vacations, and her nine-year, seven-month sobriety journey.",
        showBio: false,
      },
    ],
  },
];
